import React from 'react';
import classnames from 'classnames';
import { node, string, object } from 'prop-types';

const SectionRow = ({ children, className, style }) => (
  <tr className={classnames('ep-section-row', className)} style={style}>
    {children}
  </tr>
);

SectionRow.propTypes = {
  children: node.isRequired,
  className: string,
  style: object, //eslint-disable-line
};

SectionRow.defaultProps = {
  className: '',
  style: {},
};

export default SectionRow;
