import React from 'react';
import classNames from 'classnames';
import { number, node, string } from 'prop-types';

const SectionHeader = ({ className, children, colSpan }) => {
  const classess = classNames('ep-section__header', className);
  return (
    <tr className={classess}>
      <td colSpan={colSpan}>{children}</td>
    </tr>
  );
};

SectionHeader.propTypes = {
  colSpan: number,
  children: node.isRequired,
  className: string,
};

SectionHeader.defaultProps = {
  colSpan: 1,
  className: '',
};

export default SectionHeader;
