import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const QuestionsGroup = props => {
  const { className, ...other } = props;
  return <div className={classNames('ep-questions-group', className)} {...other} />;
};

QuestionsGroup.propTypes = {
  className: PropTypes.string,
};

QuestionsGroup.defaultProps = {
  className: '',
};

export default QuestionsGroup;
