import React from 'react';
import Immutable from 'immutable';
import { string, func, instanceOf } from 'prop-types';

const RadioWithLabel = ({ option, onChange, selectedOption }) => (
  <label className="ep-radiobutton">
    <input
      type="radio"
      className="ep-radiobutton-input"
      value={option.get('value')}
      checked={option.get('value') === selectedOption}
      onChange={onChange}
    />
    <span className="ep-radiobutton__label">{option.get('text')}</span>
    <br />
  </label>
);

RadioWithLabel.propTypes = {
  option: instanceOf(Immutable.Map).isRequired,
  onChange: func.isRequired,
  selectedOption: string,
};

RadioWithLabel.defaultProps = {
  selectedOption: null,
};

export default RadioWithLabel;
