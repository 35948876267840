import classNames from 'classnames';
import React from 'react';
import { number, string, func, bool, node } from 'prop-types';
import Counter from '../other/Counter';
import ExpandButton from '../Buttons/ExpandButton';
import SectionHeader from './SectionHeader';

const SectionHeaderWithCounterAndExpand = ({
  className,
  children,
  colSpan,
  expanded,
  index,
  withIndex,
  onToggle,
  withToggle,
}) => {
  const headerClasses = classNames(className, 'ep-section__header', {
    'ep-section__header--expanded': expanded,
    'ep-section__header--collapsed': !expanded,
  });
  return (
    <SectionHeader className={headerClasses} colSpan={colSpan}>
      {index != null && withIndex && (
        <Counter className="ep-section__header__counter" index={index} />
      )}
      <div className="ep-section__header__content">{children}</div>
      {withToggle && (
        <div className="ep-section__header__expand-toggler">
          <ExpandButton expanded={expanded} onClick={onToggle} />
        </div>
      )}
    </SectionHeader>
  );
};

SectionHeaderWithCounterAndExpand.propTypes = {
  className: string,
  children: node.isRequired,
  colSpan: number,
  expanded: bool,
  index: number,
  onToggle: func.isRequired,
  withIndex: bool,
  withToggle: bool,
};

SectionHeaderWithCounterAndExpand.defaultProps = {
  className: '',
  colSpan: 1,
  expanded: true,
  index: 1,
  withIndex: true,
  withToggle: true,
};

export default SectionHeaderWithCounterAndExpand;
