import React from 'react';
import classNames from 'classnames';
import Popover from './Popover';
import PropTypes from 'prop-types';
import dasherize from 'underscore.string/dasherize';
import _ from 'underscore';

const optionType = PropTypes.shape({
  value: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
});

const Option = function(props) {
  const restProps = _.omit(props, ['text', 'optionText']);

  return (
    <li {...restProps}>
      <span>{props.optionText != null ? props.optionText : props.text}</span>
    </li>
  );
};

Option.propTypes = {
  text: PropTypes.string.isRequired,
  optionText: PropTypes.string,
};

Option.defaultProps = {
  optionText: null,
};

class AnnotatedOption extends React.Component {
  static propTypes = {
    annotation: PropTypes.string,
    annotationClassName: PropTypes.string,
    annotationPosition: PropTypes.object,
    commonAnnotation: PropTypes.string,
    label: PropTypes.string,
  };

  static defaultProps = {
    annotation: null,
    annotationClassName: '',
    annotationPosition: { positionOffset: 0, position: 'right' },
    commonAnnotation: null,
    label: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      annotationVisible: false,
    };
    this.annotationRef = this.annotationRef.bind(this);
    this.showAnnotation = this.showAnnotation.bind(this);
    this.hideAnnotation = this.hideAnnotation.bind(this);
    this.handleOptionLeave = this.handleOptionLeave.bind(this);
  }

  annotationRef(el) {
    this.annotation = el;
  }

  showAnnotation() {
    this.setState({ annotationVisible: true });
  }

  hideAnnotation() {
    this.setState({ annotationVisible: false });
  }

  handleOptionLeave(evt) {
    // do not hide annotation if cursor is above it
    /* eslint-disable no-undef */
    if (
      evt.relatedTarget instanceof Node &&
      (this.annotation != null ? this.annotation.contains(evt.relatedTarget) : undefined)
    ) {
      return;
    }
    /* eslint-enable no-undef */
    this.hideAnnotation();
  }

  render() {
    const optionProps = _.omit(this.props, [
      'annotation',
      'label',
      'commonAnnotation',
      'annotationClassName',
      'annotationPosition',
    ]);
    const annotationClass = classNames('select-option-annotation', this.props.annotationClassName);

    return (
      <Popover
        visible={this.state.annotationVisible}
        onRequestClose={this.hideAnnotation}
        positionParams={this.props.annotationPosition}>
        <div onMouseOver={this.showAnnotation} onMouseLeave={this.handleOptionLeave}>
          <Option {...optionProps} />
        </div>
        <div
          className={annotationClass}
          ref={this.annotationRef}
          onMouseLeave={this.hideAnnotation}>
          {this.props.commonAnnotation != null ? (
            <div style={{ marginBottom: '10px' }}>{this.props.commonAnnotation}</div>
          ) : (
            undefined
          )}
          {this.props.label != null ? (
            <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>{this.props.label}</div>
          ) : (
            undefined
          )}
          <div>{this.props.annotation}</div>
        </div>
      </Popover>
    );
  }
}

class SelectCustom extends React.Component {
  static propTypes = {
    annotationClassName: PropTypes.string,
    annotationPosition: PropTypes.object,
    commonAnnotation: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onOptionHover: PropTypes.func,
    onOptionLeave: PropTypes.func,
    i18n: PropTypes.func.isRequired,
    signPosition: PropTypes.oneOf(['left', 'right']),
    signClass: PropTypes.string,
    listClassName: PropTypes.string,
    options: PropTypes.arrayOf(optionType).isRequired,
    groupedOptions: PropTypes.arrayOf(
      PropTypes.shape({ caption: PropTypes.string, options: PropTypes.arrayOf(optionType) })
    ),
    selected: PropTypes.string,
    disabled: PropTypes.bool,
    orderedList: PropTypes.bool,
    withNumberOptions: PropTypes.bool,
    withClearOption: PropTypes.bool,
  };

  static defaultProps = {
    annotationClassName: '',
    annotationPosition: { positionOffset: 0, position: 'right' },
    commonAnnotation: null,
    signPosition: 'right',
    orderedList: false,
    withNumberOptions: false,
    groupedOptions: [],
    withClearOption: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedValue: this.props.selected,
      selectedText: this.getInitiallySelectedText(),
      showingOptions: false,
    };

    this.getInitiallySelectedText = this.getInitiallySelectedText.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.showOptions = this.showOptions.bind(this);
    this.hideOptions = this.hideOptions.bind(this);
    this.onOptionOver = this.onOptionOver.bind(this);
    this.onOptionOut = this.onOptionOut.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleOptionClear = this.handleOptionClear.bind(this);
    this.renderOptionsGroup = this.renderOptionsGroup.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this._containerRef = this._containerRef.bind(this);
    this.storeContainerWidth = this.storeContainerWidth.bind(this);
  }

  _containerRef(el) {
    this.container = el;
  }

  componentDidMount() {
    this.storeContainerWidth();
    window.addEventListener('resize', this.storeContainerWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.storeContainerWidth);
  }

  storeContainerWidth() {
    this.setState({ containerWidth: this.container.offsetWidth });
  }

  getInitiallySelectedText() {
    if (this.props.selected) {
      const allOptions = _.flatten(
        this.props.options.concat(_.pluck(this.props.groupedOptions, 'options'))
      );
      const selected = _.findWhere(allOptions, { value: this.props.selected });
      return selected.text || '&zwnj;';
    } else {
      return '&zwnj;';
    }
  }

  toggleOptions() {
    if (!this.props.disabled) {
      this.setState({ showingOptions: !this.state.showingOptions });
    }
  }

  showOptions() {
    this.setState({ showingOptions: true });
  }

  hideOptions() {
    this.setState({ showingOptions: false });
  }

  onOptionOver(ev) {
    if (!this.props.onOptionHover) {
      return;
    }
    const value = ev.target.getAttribute('data-value');
    this.props.onOptionHover(value, ev.target);
  }

  onOptionOut(ev) {
    typeof this.props.onOptionLeave === 'function' ? this.props.onOptionLeave(ev) : undefined;
  }

  handleSelect(option) {
    return evt => {
      evt.stopPropagation();
      evt.preventDefault();
      // ignore self selection and disabled option selection
      if (option.disabled || option.value === this.state.selectedValue) {
        return;
      }
      this.setState({
        selectedValue: option.value,
        selectedText: option.text,
        showingOptions: false,
      });

      return typeof this.props.onChange === 'function'
        ? this.props.onChange(option.value)
        : undefined;
    };
  }

  handleOptionClear() {
    this.hideOptions();
    this.props.onChange(null);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) this.setState({ selectedText: this.getInitiallySelectedText() });
  }

  renderOptionsGroup(group, idx) {
    const { caption, options } = group;
    return (
      <li className="options-group" key={idx}>
        <div className="options-group__caption">{caption}</div>
        <div className="options-group__options">
          <ul>{options.map(this.renderOption)}</ul>
        </div>
      </li>
    );
  }

  renderOption(option, idx) {
    const { value, text, optionText, disabled, title, annotation, label } = option;
    const isSelected = this.state.selectedValue === value;
    const itemClass = classNames('option', dasherize(value), {
      selected: isSelected,
    });

    const optionProps = {
      className: itemClass,
      'data-index': idx,
      'data-value': value,
      onClick: this.handleSelect(option),
      key: idx,
      disabled,
      title,
      text,
      optionText,
    };

    if (annotation) {
      return (
        <AnnotatedOption
          {...Object.assign({}, optionProps, {
            annotation: annotation,
            label: label,
            commonAnnotation: this.props.commonAnnotation,
            annotationClassName: this.props.annotationClassName,
            annotationPosition: this.props.annotationPosition,
          })}
        />
      );
    } else {
      return <Option {...Object.assign({}, optionProps)} />;
    }
  }

  render() {
    const containerClass = classNames('options-select-container', this.props.className, {
      disabled: this.props.disabled,
      'showing-options': this.state.showingOptions,
    });
    const selectInputClass = classNames('select-input', `sign-position-${this.props.signPosition}`);
    const optionsClass = classNames('options-select-container__options', this.props.listClassName, {
      'with-numbered-options': this.props.withNumberOptions,
    });
    const signClass = classNames('select-sign', this.props.signClass);
    const ListTag = this.props.orderedList ? 'ol' : 'ul';

    return (
      <Popover
        visible={this.state.showingOptions}
        onRequestClose={this.hideOptions}
        positionParams={{ positionOffset: 0 }}>
        <div
          className={containerClass}
          onClick={!this.props.disabled ? this.toggleOptions : undefined}
          ref={this._containerRef}>
          <div className={selectInputClass}>
            <div
              className="text-box"
              dangerouslySetInnerHTML={{ __html: this.state.selectedText }}
            />
            <div className={signClass} />
          </div>
        </div>
        <ListTag
          className={optionsClass}
          onMouseOver={this.onOptionOver}
          onMouseLeave={this.onOptionOut}
          style={{ minWidth: `${this.state.containerWidth}px` }}>
          {this.props.groupedOptions.map(this.renderOptionsGroup)}
          {this.props.options.map(this.renderOption)}
          {this.props.withClearOption && this.props.selected ? (
            <li className="clear-option btn-popup" onClick={this.handleOptionClear}>
              {this.props.i18n('actions.clear')}
            </li>
          ) : null}
        </ListTag>
      </Popover>
    );
  }
}

export default SelectCustom;
