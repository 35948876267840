import React, { PureComponent } from 'react';
import { string, oneOf, oneOfType, number, node } from 'prop-types';
import classnames from 'classnames';

export class DataTableCell extends PureComponent {
  static propTypes = {
    className: string,
    type: oneOf(['string', 'number', 'custom']),
    value: oneOfType([string, number]),
    children: node,
  };

  static defaultProps = {
    className: '',
    type: 'string',
    value: null,
    children: null,
  };

  cellContent() {
    switch (this.props.type) {
      case 'string':
      case 'number':
        return this.props.value;
      case 'custom':
        return this.props.children;
      default:
        return this.props.value;
    }
  }

  render() {
    return (
      <td className={classnames('ep-data-table-cell', this.props.className)}>
        {this.cellContent()}
      </td>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
export class DataTableHeaderCell extends PureComponent {
  static propTypes = {
    children: node.isRequired,
    className: string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <th className={classnames('ep-data-table-head-cell', this.props.className)}>
        {this.props.children}
      </th>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
export class DataTableRow extends PureComponent {
  static propTypes = {
    children: node.isRequired,
    className: string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <tr className={classnames('ep-data-table-row', this.props.className)}>
        {this.props.children}
      </tr>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
export class DataTableHeader extends PureComponent {
  static propTypes = { children: node.isRequired, className: string };

  static defaultProps = { className: '' };

  render() {
    return (
      <thead className={classnames('ep-data-table-header', this.props.className)}>
        {this.props.children}
      </thead>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
export class DataTableBody extends PureComponent {
  static propTypes = { children: node.isRequired, className: string };

  static defaultProps = { className: '' };

  render() {
    // TODO: add selection, hover
    return (
      <tbody className={classnames('ep-data-table-body', this.props.className)}>
        {React.Children.map(this.props.children, child => child)}
      </tbody>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
export class DataTable extends PureComponent {
  static propTypes = {
    className: string,
    children: node.isRequired,
    type: oneOf(['', 'stripped']),
  };

  static defaultProps = {
    className: '',
    type: '',
  };

  render() {
    const tableClass = classnames('ep-data-table', this.props.type, this.props.className);
    return <table className={tableClass}>{this.props.children}</table>;
  }
}
