import React from 'react';
import classNames from 'classnames';
import _ from 'underscore';
import PropTypes from 'prop-types';

const observerOptions = {
  childList: true,
  subtree: true,
  characterData: true,
  attributes: true,
};

class DynamicHeightContainer extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onTransitionEnd: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    onTransitionEnd: null,
  };

  constructor(props) {
    super(props);
    this.container = null;
    this.innerContainer = null;
    this._ref = this._ref.bind(this);
    this._refInner = this._refInner.bind(this);
    this.updateContainerHeight = this.updateContainerHeight.bind(this);
    this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
  }

  componentDidMount() {
    this.updateContainerHeight();
    if (window.MutationObserver) {
      this.observer = new window.MutationObserver(this.updateContainerHeight);
      this.observer.observe(this.innerContainer, observerOptions);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.observer) return;
    this.updateContainerHeight();
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  _ref(el) {
    this.container = el;
  }

  _refInner(el) {
    this.innerContainer = el;
  }

  updateContainerHeight(mutations) {
    this.container.style.height = `${this.innerContainer.scrollHeight}px`;
  }

  handleTransitionEnd(evt) {
    /*
      # nobody said that this element's child somewhere in the tree will not be another
      # DynamicHeightContainer.In that case we won't adjust this component's height properly
      # because`@innerContainer.scrollHeight` in the updateContainerHeight() method will still
      # hold old height value of the child DynamicHeightContainer.Only upon onTransitionEnd actual
      # height can be calculated
     */
    if (evt.target.classList.contains('ep-dynamic-height-container')) {
      this.updateContainerHeight();
    }

    this.props.onTransitionEnd && this.props.onTransitionEnd();
  }

  render() {
    return (
      <div
        className={classNames('ep-dynamic-height-container', this.props.className)}
        ref={this._ref}
        onTransitionEnd={this.handleTransitionEnd}>
        <div className="ep-dynamic-height-container__inner" ref={this._refInner}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default DynamicHeightContainer;
