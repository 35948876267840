import React from 'react';
import classNames from 'classnames';
import { string, func, bool, node } from 'prop-types';

const Switcher = ({ buttonText, checked, className, disabled, onChange }) => {
  return (
    <div className={classNames('ep-switcher', className)}>
      <label className="ep-switcher__switcher" onClick={!disabled ? onChange : null}>
        <input className="hidden" type="checkbox" disabled="disabled" checked={checked} />
        <div className={classNames('switcher__slider', { disabled: disabled })} />
      </label>
      {buttonText && (
        <button onClick={onChange} disabled={disabled}>
          <span className="ep-switcher__switch-btn-text">{buttonText}</span>
        </button>
      )}
    </div>
  );
};

Switcher.propTypes = {
  buttonText: node,
  className: string,
  checked: bool,
  disabled: bool,
  onChange: func.isRequired,
};

Switcher.defaultProps = {
  checked: false,
  disabled: false,
  className: null,
  buttonText: null,
};

export default Switcher;
