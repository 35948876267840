import classnames from 'classnames';
import React from 'react';
import { func, string, bool } from 'prop-types';
import ArrowUp from '../../assets/arrow-up.svg';
import ArrowDown from '../../assets/arrow-down.svg';

const ExpandButton = ({ className, expanded, onClick }) => {
  const btnClasses = classnames('ep-expand-button', className, {
    'ep-expand-button--expanded': expanded,
    'ep-expand-button--collapsed': !expanded,
  });

  return (
    <button onClick={onClick} className={btnClasses}>
      {expanded ? <ArrowUp /> : <ArrowDown />}
    </button>
  );
};

ExpandButton.propTypes = {
  className: string,
  expanded: bool,
  onClick: func.isRequired,
};

ExpandButton.defaultProps = {
  className: '',
  expanded: true,
};

export default ExpandButton;
