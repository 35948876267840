import Immutable from 'immutable';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { removeHTMLNode } from '../../utils/utils';

/* eslint-disable react/no-find-dom-node */

let POPOVERS_STACK = Immutable.Stack();

const CONTAINER_CLASS = 'PopupBox_Container';

const MIN_CONTAINER_HEIGHT = '50';

const DEFAULT_CONTAINER_STYLES = `
  position:fixed;
  z-index:110;
  opacity:0;
  transition: opacity .8s cubic-bezier(0, 0.34, 0.45, 1.5);
`;

const DEFAULT_POSITION_SPEC = {
  position: 'bottom', // 'bottom' | 'top' | 'left' | 'right', 'cover'
  alignment: 'start', // 'start' | 'center' | 'end'
  positionOffset: 5, // distance in pixels between container edge and anchor edge
};

const OPPOSITES = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
  cover: 'cover',
};

// getAlignmentOffset :: RectObject -> RectObject -> String -> String -> Number
const getAlignmentOffset = function (anchorRect, containerRect, alignment, position) {
  switch (alignment) {
    case 'start':
      return 0;
    case 'center':
      if (_.contains(['top', 'bottom', 'cover'], position)) {
        return (anchorRect.width - containerRect.width) / 2;
      } else {
        return (anchorRect.height - containerRect.height) / 2;
      }
    case 'end':
      if (_.contains(['top', 'bottom', 'cover'], position)) {
        return -(containerRect.width - anchorRect.width);
      } else {
        return -(containerRect.height - anchorRect.height);
      }
  }
};

// primaryOffsetGetter :: RectObject -> RectObject -> Number -> (String -> Number)
const primaryOffsetGetter = (anchorRect, containerRect, positionOffset) =>
  function (position) {
    const anchorEdgeOffset = anchorRect[position];

    switch (position) {
      case 'top':
        return anchorEdgeOffset - (containerRect.height + positionOffset);
      case 'left':
        return anchorEdgeOffset - (containerRect.width + positionOffset);
      case 'bottom':
      case 'right':
        return anchorEdgeOffset + positionOffset;
      default:
        return anchorRect['top'] + positionOffset;
    }
  };

// getSecondaryOffset :: RectObject -> RectObject -> String -> String -> Number
const getSecondaryOffset = function (anchorRect, containerRect, alignment, position) {
  // alignment offset is an offset of container element relative to anchor element. It depends on
  // position
  let secondaryOffset;
  const alignmentOffset = getAlignmentOffset(anchorRect, containerRect, alignment, position);

  // eslint-disable-next-line no-undef
  const { clientWidth, clientHeight } = document.documentElement;
  switch (position) {
    case 'top':
    case 'bottom':
    case 'cover':
      secondaryOffset = anchorRect.left + alignmentOffset;
      // ensure container won't overflow the screen
      if (secondaryOffset + containerRect.width > clientWidth) {
        secondaryOffset = clientWidth - containerRect.width;
      }
      break;
    case 'left':
    case 'right':
      secondaryOffset = anchorRect.top + alignmentOffset;
      // ensure container won't overflow the screen
      if (secondaryOffset + containerRect.height > clientHeight) {
        secondaryOffset = clientHeight - containerRect.height;
      }
      break;
  }
  return secondaryOffset;
};

//  primaryOffsetOverflow :: Number -> RectObject -> String -> Number
const primaryOffsetOverflow = function (offset, containerRect, position) {
  // distance between container edge and the screen edge in `position` direction.
  const containerEdgeOffset = (() => {
    switch (position) {
      case 'top':
      case 'left':
      case 'cover':
        return offset;
      case 'right':
        // eslint-disable-next-line no-undef
        return document.documentElement.clientWidth - (offset + containerRect.width);
      case 'bottom':
        // eslint-disable-next-line no-undef
        return document.documentElement.clientHeight - (offset + containerRect.height);
    }
  })();

  // positive value means no overflow
  return containerEdgeOffset > 0 ? 0 : Math.abs(containerEdgeOffset);
};

// containerPositionToStylesString :: String -> Number -> Number -> ?Number -> String
const containerPositionToStylesString = function (
  position,
  primaryOffset,
  secondaryOffset,
  containerHeight
) {
  // if containerHeight is provided, it means there will be a scrollbar, need to take its width into
  // account
  const layoutStyles =
    containerHeight != null ? `height:${containerHeight}px;overflow-y:auto;` : '';
  // container may not be positioned outside of viewport, that is its top or left values may not
  // be negative
  const safePrimaryOffset = Math.max(primaryOffset, 0);
  const safeSecondaryOffset = Math.max(secondaryOffset, 0);

  switch (position) {
    case 'top':
    case 'bottom':
    case 'cover':
      return layoutStyles + `top:${safePrimaryOffset}px;left:${safeSecondaryOffset}px;`;
    case 'right':
    case 'left':
      return layoutStyles + `top:${safeSecondaryOffset}px;left:${safePrimaryOffset}px;`;
  }
};

// getContainerOffsets :: HTMLelement -> HTMLElement -> Object -> IO String
const getPositionStylesString = function (anchorEl, containerEl, positionSpec) {
  // get position spec params, falling back to defaults if any of them were not provided
  let adjustedContainerHeight;
  if (positionSpec == null) {
    positionSpec = {};
  }
  let { position, alignment, positionOffset } = _.defaults(positionSpec, DEFAULT_POSITION_SPEC);
  const desiredPosition = position;

  // collect bounding rects of anchor and container elements
  const anchorRect = anchorEl.getBoundingClientRect();
  const containerRect = containerEl.getBoundingClientRect();

  const primaryOffsetProvider = primaryOffsetGetter(anchorRect, containerRect, positionOffset);

  // we calculate 2 offsets (primary and secondary). "Primary" offset corresponds to "position"
  // parameter and "secondary" - to the "alignment" parameter in the position spec.
  // These values will be later put as `top` or `left` CSS props of container element to get it
  // positioned as requested
  let primaryOffset = primaryOffsetProvider(position);
  let secondaryOffset = getSecondaryOffset(anchorRect, containerRect, alignment, position);

  // Check if primary offset will not cause container overflowing the screen edge. If it does cause
  // the overflow then (1) try the inversed position (e.g. if desired position is `top`, then try
  // `bottom`), if the inversed position also causes overflow, then (2) choose between desired and
  // inversed position selecting the one which gives the most of visible space and then reduce the
  // height of the container to avoid the overflow.
  const primaryOverflow = primaryOffsetOverflow(primaryOffset, containerRect, position);
  if (primaryOverflow > 0) {
    const inversedPosition = OPPOSITES[position];
    const inversedOffset = primaryOffsetProvider(inversedPosition);
    const inversedOverflow = primaryOffsetOverflow(inversedOffset, containerRect, inversedPosition);

    // inversed position doesn't overflow
    if (inversedOverflow === 0) {
      primaryOffset = inversedOffset;
      position = inversedPosition;
      // inversed position also overflows, select whichever has more space for content
    } else {
      if (inversedOverflow < primaryOverflow) {
        primaryOffset = inversedOffset;
      }
      if (inversedOverflow < primaryOverflow) {
        position = inversedPosition;
      }
      adjustedContainerHeight = Math.max(
        MIN_CONTAINER_HEIGHT,
        containerRect.height - Math.min(primaryOverflow, inversedOverflow)
      );
    }

    // the position might have changed at this point, so update the secondary offset if needed
    if (position !== desiredPosition) {
      secondaryOffset = getSecondaryOffset(anchorRect, containerRect, alignment, position);
    }
  }

  return containerPositionToStylesString(
    position,
    primaryOffset,
    secondaryOffset,
    adjustedContainerHeight
  );
};

const handleContainerDragStart = function (evt) {
  evt.dataTransfer.setData('text/plain', null);
  evt.currentTarget.setAttribute('data-initialX', evt.screenX);
  evt.currentTarget.setAttribute('data-initialY', evt.screenY);
};

const handleContainerDragEnd = function (evt) {
  const { screenX, screenY, currentTarget } = evt;
  dragContainer(currentTarget, screenX, screenY);
  currentTarget.setAttribute('data-initialX', null);
  currentTarget.setAttribute('data-initialY', null);
};

const dragContainer = function ($container, screenX, screenY) {
  const traveledY = screenY - $container.getAttribute('data-initialY');
  const traveledX = screenX - $container.getAttribute('data-initialX');
  const { offsetTop, offsetLeft } = $container;
  $container.style.left = `${offsetLeft + traveledX}px`;
  $container.style.top = `${offsetTop + traveledY}px`;
};

const addDragEventsListeners = function ($el) {
  if ($el == null) {
    return;
  }
  $el.addEventListener('dragstart', handleContainerDragStart);
  $el.addEventListener('dragend', handleContainerDragEnd);
};

const removeDragEventsListeners = function ($el) {
  if ($el == null) {
    return;
  }
  $el.removeEventListener('dragstart', handleContainerDragStart);
  $el.removeEventListener('dragend', handleContainerDragEnd);
};

// preparePopupBoxContainer :: () -> IO HTMLElement
const preparePopupBoxContainer = function (draggable) {
  if (draggable == null) {
    draggable = false;
  }
  // eslint-disable-next-line no-undef
  const container = document.createElement('div');
  container.setAttribute('style', DEFAULT_CONTAINER_STYLES);
  container.setAttribute('class', CONTAINER_CLASS);
  if (draggable) {
    container.setAttribute('draggable', true);
    addDragEventsListeners(container);
  }
  // eslint-disable-next-line no-undef
  document.body.appendChild(container);
  return container;
};

// setContainerStyles :: HTMLelement -> HTMLElement -> IO ()
const setContainerStyles = function (containerEl, anchorEl, positionSpec) {
  // set calculated values
  const positionStyles = getPositionStylesString(anchorEl, containerEl, positionSpec);
  containerEl.setAttribute('style', DEFAULT_CONTAINER_STYLES + positionStyles + 'opacity:1;');
};

class Popover extends React.Component {
  static propTypes = {
    draggable: PropTypes.bool,
    visible: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    // TODO: improve validation of target and content props. They can also be provided as 1st and
    // 2nd child elements respectively
    target: PropTypes.element,
    content: PropTypes.element,
    positionParams: PropTypes.shape({
      position: PropTypes.oneOf(['bottom', 'top', 'left', 'right', 'cover']),
      alignment: PropTypes.oneOf(['start', 'center', 'end']),
      positionOffset: PropTypes.number,
    }),
    children(props, propName, componentName) {
      const children = props[propName];
      if (children.length > 2) {
        throw new Error(`${componentName} must not have more than 2 child elements.`);
      }
      return null;
    },
  };

  constructor(props) {
    super(props);

    this.forceClose = this.forceClose.bind(this);
    this.getContentEl = this.getContentEl.bind(this);
    this.getTargetEl = this.getTargetEl.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.prepareContent = this.prepareContent.bind(this);
    this.renderChild = this.renderChild.bind(this);
    this.unmountChild = this.unmountChild.bind(this);
    this.targetRef = this.targetRef.bind(this);
    this.wrappedChildRef = this.wrappedChildRef.bind(this);
    this.maybeCloseOnScroll = this.maybeCloseOnScroll.bind(this);
  }

  componentDidMount() {
    this.renderChild();
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', this.forceClose);
    // eslint-disable-next-line no-undef
    window.addEventListener('scroll', this.maybeCloseOnScroll, true);
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-undef
    window.removeEventListener('resize', this.forceClose);
    // eslint-disable-next-line no-undef
    window.removeEventListener('scroll', this.maybeCloseOnScroll);
    this.unmountChild();
    if (this.container) {
      if (this.props.draggable) {
        removeDragEventsListeners(this.container);
      }
      removeHTMLNode(this.container);
      this.container != null ? this.container : (this.container = null);
    }
  }

  maybeCloseOnScroll(evt) {
    // don't react on popover container scrolling
    if (evt.target === this.container) return;
    this.forceClose();
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible) {
      this.renderChild(!prevProps.visible);
    } else if (prevProps.visible) {
      this.unmountChild();
    }
  }

  forceClose() {
    if (this.props.visible) {
      return typeof this.props.onRequestClose === 'function'
        ? this.props.onRequestClose()
        : undefined;
    }
  }

  getContentEl() {
    const { children, content } = this.props;
    if (content != null) {
      return content;
    }

    if (children.length === 2) {
      return _.last(React.Children.toArray(children));
    } else {
      return null;
    }
  }

  getTargetEl() {
    const { target, children } = this.props;
    if (target != null) {
      return target;
    }

    return _.first(React.Children.toArray(children));
  }

  handleBlur(evt) {
    const $targetEl = ReactDOM.findDOMNode(this.targetEl);
    const focusMovedToDescendantEl =
      this.container != null && this.container.contains(evt.relatedTarget);
    const focusMovedToTargetEl = evt.relatedTarget === $targetEl;
    // ignore focusing descendant elements as well as the target element
    if (focusMovedToDescendantEl || focusMovedToTargetEl) {
      return evt.preventDefault();
    }
    const focusedPopover = POPOVERS_STACK.first();
    const $focusedTargetEl = ReactDOM.findDOMNode(focusedPopover.targetEl);
    // Current popover's content is itself a popover. Since popover's content is not part of
    // targetEl DOM tree, we use popovers stack to test whether current popover (the on being
    // blurred) content doesn't contain the active (one the focused moved to) popover's target
    // element (this happens in case of nested popovers where one popover's content is parent of
    // another popover's target element). In such case we must prevent closing action of the current
    // popover, otherwise its decedents will get unmounted
    const focusMovedToChildPopover =
      this.wrappedChild != null ? this.wrappedChild.contains($focusedTargetEl) : undefined;
    if (focusMovedToChildPopover) {
      return evt.preventDefault();
    }
    // Current popover is a target element of another popover. We also use popovers stack to test
    // whether current popover's (the one being blurred) targetEl is not a child of the active (the
    // one the focus moved to) popover's targetEl. Of course this makes sense only when current
    // popover and the focused popover are 2 different instances
    const focusMovedToParentPopover =
      focusedPopover !== this && $focusedTargetEl.contains($targetEl);
    if (focusMovedToParentPopover) {
      return evt.preventDefault();
    }
    return typeof this.props.onRequestClose === 'function'
      ? this.props.onRequestClose(evt)
      : undefined;
  }

  handleKeyDown(evt) {
    if (evt.keyCode === 27) {
      this.handleBlur(evt);
    }
  }

  targetRef(el) {
    this.targetEl = el;
  }

  wrappedChildRef(el) {
    this.wrappedChild = el;
  }

  prepareContent() {
    // wrap child to have control over its focus
    const contentEl = this.getContentEl();
    if (!contentEl) {
      return null;
    }

    return (
      <div
        className="PopupBox_Content"
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
        ref={this.wrappedChildRef}
        tabIndex="-1">
        {contentEl}
      </div>
    );
  }

  renderChild(initialRender) {
    if (initialRender == null) {
      initialRender = true;
    }
    if (!this.props.visible) {
      return;
    }
    if (this.container == null) {
      let draggable;
      this.container = preparePopupBoxContainer((draggable = this.props.draggable));
    }

    ReactDOM.render(this.prepareContent(), this.container, () => {
      if (initialRender) {
        // once any popover shows its content it goes on top of the stack, being the active one
        POPOVERS_STACK = POPOVERS_STACK.unshift(this);
        this.wrappedChild.focus();
        return setContainerStyles(
          this.container,
          ReactDOM.findDOMNode(this.targetEl),
          this.props.positionParams
        );
      }
    });
  }

  unmountChild() {
    if (this.container != null) {
      ReactDOM.unmountComponentAtNode(this.container);
      // reset container styles back to default
      this.container.setAttribute('style', DEFAULT_CONTAINER_STYLES);
    }

    // once any popover hides its content it goes off the stack and if there are several
    // interrelated popovers on stack we need to return focus to the next related popover
    if (POPOVERS_STACK.first() === this) {
      POPOVERS_STACK = POPOVERS_STACK.shift();
      const nextPopover = POPOVERS_STACK.first();
      if (nextPopover) {
        // moving focus back to parent popover
        const $targetEl = ReactDOM.findDOMNode(this.targetEl);
        if (
          nextPopover.wrappedChild != null
            ? nextPopover.wrappedChild.contains($targetEl)
            : undefined
        ) {
          if (nextPopover.wrappedChild != null) {
            nextPopover.wrappedChild.focus();
          }
        }
        // moving focus back to child popover
        const $nextPopoverTargetEl = ReactDOM.findDOMNode(nextPopover.targetEl);
        if ($targetEl != null ? $targetEl.contains($nextPopoverTargetEl) : undefined) {
          return nextPopover.wrappedChild != null ? nextPopover.wrappedChild.focus() : undefined;
        }
      }
    }
  }

  render() {
    const targetEl = this.getTargetEl();

    return React.cloneElement(targetEl, {
      tabIndex: targetEl.props.tabIndex != null ? targetEl.props.tabIndex : 0,
      ref: (el) => {
        if (typeof targetEl.ref === 'function') {
          targetEl.ref(el);
        }
        return this.targetRef(el);
      },
    });
  }
}

export default Popover;
