import React, { PureComponent } from 'react';
import { objectOf, string, number, node, oneOfType, bool, func, element } from 'prop-types';
import classNames from 'classnames';

class Button extends PureComponent {
  static propTypes = {
    baseStyle: string,
    className: string,
    children: node,
    style: objectOf(oneOfType([string, number])),
    type: string,
    disabled: bool,
    label: string,
    icon: oneOfType([element, string]),
    // place icon after of before label
    iconAfter: bool,
    // if `true` then icon will be aligned left/right depending on `iconAfter`
    // and label to the opposite direction
    alignIcon: bool,
    onClick: func,
    onFocus: func,
    onBlur: func,
    tooltip: string,
  };

  static defaultProps = {
    baseStyle: 'ep',
    className: '',
    children: null,
    disabled: false,
    label: null,
    style: {},
    type: '',
    icon: null,
    iconAfter: true,
    alignIcon: true,
    onClick: () => null,
    onFocus: () => null,
    onBlur: () => null,
    tooltip: '',
  };

  btnContent() {
    const styles = {
      label: {},
      icon: {},
    };

    if (this.props.icon && this.props.alignIcon) {
      if (this.props.iconAfter) {
        styles.label = { float: 'left' };
        styles.icon = { float: 'right' };
      } else {
        styles.label = { float: 'right' };
        styles.icon = { float: 'left' };
      }
    }

    const label = (
      <span key="label" className={`${this.props.baseStyle}-btn-label`} style={styles.label}>
        {this.props.children || this.props.label}
      </span>
    );

    if (this.props.icon) {
      const iconEl = (
        <div key="icon" className={`${this.props.baseStyle}-btn-icon`} style={styles.icon}>
          {this.props.icon}
        </div>
      );

      const els = [label, iconEl];

      if (this.props.iconAfter) {
        return els;
      }

      return els.reverse();
    }

    return label;
  }

  render() {
    const className = classNames(
      `${this.props.baseStyle}-btn`,
      `${this.props.baseStyle}-btn--${this.props.type}`,
      this.props.className
    );
    return (
      <button
        className={className}
        disabled={this.props.disabled}
        style={this.props.style}
        onClick={this.props.onClick}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        data-tip={this.props.tooltip || ''}>
        {this.btnContent()}
      </button>
    );
  }
}

export default Button;
