import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import translate from '../HOC/translate';
import Button from '../Buttons/Button';

import '../../styles/components/textAreaWithApply.styl';

@translate()
class TextAreaWithApply extends React.PureComponent {
  static propTypes = {
    i18n: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    content: PropTypes.string,
    maxHeight: PropTypes.number,
    minHeight: PropTypes.number,
    readOnly: PropTypes.bool,
    editing: PropTypes.bool,
  };

  static defaultProps = {
    onCancel: null,
    content: null,
    minHeight: 80,
    maxHeight: 200,
    readOnly: false,
    editing: false,
  };

  constructor(props) {
    super();
    this.state = {
      editing: !props.readOnly && props.editing,
    };
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.handleHeightChange();
  }

  componentWillUpdate(nextProps, nextState) {
    if (!this.state.editing && nextState.editing) {
      this.initialContent = this.props.content;
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onApply = () => {
    this.props.onApply(this.textArea.value);
    this.close();
  };

  onBlur = e => {
    e.stopPropagation();
    if (e.relatedTarget && e.relatedTarget.classList.contains('gdt-btn-cancel')) {
      return this.onCancel();
    }
    return this.onApply();
  };

  onCancel = () => {
    const { onCancel, onApply } = this.props;
    if (onCancel) {
      onCancel();
    } else if (this.initialContent && this.initialContent !== this.textArea.value) {
      onApply(this.initialContent);
    }
    this.close();
  };

  close = () => {
    this.initialContent = null;
    if (this.mounted) {
      this.setState({
        editing: false,
      });
    }
  };

  textAreaRef = ref => {
    this.textArea = ref;
  };

  startEditing = () => {
    if (!this.props.readOnly) {
      this.setState({ editing: true });
    }
  };

  handleHeightChange = () => {
    this.textArea.style.height = 'inherit';
    this.textArea.style.height = this.textArea.scrollHeight + 'px';
  };

  render() {
    const { i18n, content, readOnly } = this.props;
    const { editing } = this.state;
    const containerClassName = classNames({
      editing,
      readOnly,
      'text-area-container': true,
    });

    return (
      <div className={containerClassName}>
        <textarea
          ref={this.textAreaRef}
          defaultValue={content}
          onBlur={this.onBlur}
          onClick={this.startEditing}
          onInput={this.handleHeightChange}
          style={{
            minHeight: `${this.props.minHeight}px`,
            maxHeight: `${this.props.maxHeight}px`,
          }}
        />
        {editing && (
          <div className="buttons">
            <Button
              type="cancel"
              onClick={this.onCancel}
              label={i18n('actions.cancel')}
              baseStyle="gdt"
            />
            <Button
              type="apply"
              onClick={this.onApply}
              label={i18n('actions.apply')}
              baseStyle="gdt"
            />
          </div>
        )}
      </div>
    );
  }
}

export default TextAreaWithApply;
