import React from 'react';
import classNames from 'classnames';
import { string, number } from 'prop-types';
import CircleBg from '../../assets/circle.svg';

const Counter = ({ index, className }) => {
  const counterClass = classNames('counter', className);
  return (
    <div className={counterClass}>
      <CircleBg />
      <div className="counter_number">{index}</div>
    </div>
  );
};

Counter.propTypes = {
  className: string,
  index: number.isRequired,
};

Counter.defaultProps = {
  className: '',
};

export default Counter;
