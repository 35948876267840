import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CardContent = ({ className, headerBackground, children }) => (
  <div className={classnames('card__card-content', className, { 'header-bg': headerBackground })}>
    {children}
  </div>
);

CardContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  headerBackground: PropTypes.bool,
};

CardContent.defaultProps = {
  className: '',
  headerBackground: false,
};

export default CardContent;
