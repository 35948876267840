import React from 'react';
import classnames from 'classnames';
import { string, number, node, object } from 'prop-types';

const CellWithTitle = ({ className, rowSpan, colSpan, children, cellTitle, style }) => {
  const classes = classnames('ep-cell', className);
  return (
    <td colSpan={colSpan} rowSpan={rowSpan} className={classes} style={style}>
      {cellTitle && <h2>{cellTitle}</h2>}
      <div className="ep-cell__content">{children}</div>
    </td>
  );
};

CellWithTitle.propTypes = {
  className: string,
  cellTitle: string,
  children: node.isRequired,
  colSpan: number,
  rowSpan: number,
  // eslint-disable-next-line react/forbid-prop-types
  style: object,
};

CellWithTitle.defaultProps = {
  className: '',
  rowSpan: 1,
  colSpan: 1,
  cellTitle: null,
  style: {},
};

export default CellWithTitle;
