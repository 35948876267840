import dasherize from 'underscore.string/dasherize';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import Checkbox from './Checkbox';

const optionType = PropTypes.shape({
  value: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
});

const GroupedCheckboxes = ({ name, groupedOptions, onChange, selectedOptions }) => (
  <div className="ep-grouped-checkboxes">
    {_.map(groupedOptions, (group, groupId) => (
      <div className="ep-grouped-checkboxes__group" key={groupId}>
        <div className="ep-grouped-checkboxes__caption">{group.caption}</div>
        {group.options.map(option => (
          <Checkbox
            checked={_.contains(selectedOptions[groupId], option.value)}
            key={option.value}
            name={name}
            label={option.text}
            onChange={onChange(groupId, option.value)}
          />
        ))}
      </div>
    ))}
  </div>
);

GroupedCheckboxes.propTypes = {
  groupedOptions: PropTypes.objectOf(
    PropTypes.shape({
      caption: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(optionType).isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedOptions: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default GroupedCheckboxes;
