import React from 'react';
import PropTypes from 'prop-types';

const CardHeader = ({ title, children }) => (
  <div className="card__card-header">
    {title && <h1>{title}</h1>}
    {children}
  </div>
);

CardHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

CardHeader.defaultProps = {
  title: null,
  children: null,
};

export default CardHeader;
