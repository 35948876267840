import React from 'react';
import PropTypes from 'prop-types';
import createFragment from 'react-addons-create-fragment';
import _ from 'underscore';

import stringUtils from '../../utils/stringUtils';

const goUpRegex = /\.\.\//g;
const namespaceSeparatorRegex = /[.:]/;

const translate = (namespace = '') => Component => {
  const getKeyWithNamespace = key => {
    if (stringUtils.startsWith(key, '/')) {
      return key.substr(1, key.length);
    }
    if (stringUtils.startsWith(key, '../')) {
      const namespaceTree = namespace.split(namespaceSeparatorRegex);

      if (namespaceTree.length === 1) {
        return key;
      }

      const levelsToGoUp = key.match(goUpRegex).length;
      const normalizedKey = key.replace(goUpRegex, '');

      return (
        namespaceTree
          // navigate up in namespace tree
          .slice(0, namespaceTree.length - levelsToGoUp)
          // prepare keyWithNamespace array
          .concat([normalizedKey])
          // return a resulting keyWithNamespace string
          .join('.')
          // first '.' needs to be replaced with ':' as head of resulting
          // path is the root of translation namespace
          .replace('.', ':')
      );
    }

    if (namespace.length) {
      const separator = stringUtils.endsWith(namespace, ':') ? '' : '.';
      return `${namespace}${separator}${key}`;
    }

    return key;
  };

  const i18n = (tFunc, key, params = {}, componentParams = {}) => {
    const componentParamKey = paramKey => `\0${paramKey}\0`;
    const paramsWithComponentParams = _.extend(
      {},
      params,
      _.chain(componentParams)
        .keys()
        .reduce((acc, componentParam) => {
          acc[componentParam] = componentParamKey(componentParam);
          return acc;
        }, {})
        .value()
    );

    const translatedString = tFunc(getKeyWithNamespace(key), paramsWithComponentParams);

    if (!_.isEmpty(componentParams)) {
      const [replacedString, fragments] = _.chain(componentParams)
        .pairs()
        .reduce(
          ([accStr, accFragments], [paramKey, component]) => {
            const split = accStr.split(componentParamKey(paramKey));
            const newFragments = {
              [`text_${paramKey}`]: split[0],
              [`comp_${paramKey}`]: component,
            };
            return [split[1], _.extend(accFragments, newFragments)];
          },
          [translatedString, {}]
        )
        .value();

      fragments.last = replacedString;

      return createFragment(fragments);
    }

    return translatedString;
  };

  class TranslatedComponent extends React.PureComponent {
    static propTypes = {
      i18n: PropTypes.func,
    };

    static defaultProps = {
      i18n: null,
    };

    static contextTypes = {
      i18n: PropTypes.func,
    };

    render() {
      const { i18n: i18nProp, ...props } = this.props;
      const { i18n: i18nContext } = this.context;
      // eslint-disable-next-line no-undef
      const $t = window.$ && window.$.t;
      const tFunc = i18nProp || i18nContext || $t || (x => x);

      return <Component i18n={_.partial(i18n, tFunc)} {...props} />;
    }
  }

  return TranslatedComponent;
};

export default translate;
