import React from 'react';
import { number, string, bool, node } from 'prop-types';
import SectionHeader from './SectionHeader';
import Counter from '../other/Counter';

const SectionHeaderWithCounter = ({ className, children, colSpan, index, withIndex }) => (
  <SectionHeader className={className} colSpan={colSpan}>
    {index && withIndex && <Counter className="ep-section__header__counter" index={index} />}
    <div className="ep-section__header__content">{children}</div>
  </SectionHeader>
);

SectionHeaderWithCounter.propTypes = {
  colSpan: number,
  index: number,
  children: node.isRequired,
  withIndex: bool,
  className: string,
};

SectionHeaderWithCounter.defaultProps = {
  colSpan: 1,
  withIndex: true,
  index: 1,
  className: '',
};

export default SectionHeaderWithCounter;
