import React from 'react';
import { string, func, arrayOf, shape, bool, oneOf } from 'prop-types';
import classNames from 'classnames';
import _ from 'underscore';

import stringUtils from '../../utils/stringUtils';

import ArrowDown from '../../assets/arrow-down.svg';
import '../../styles/components/customSelect.styl';

const optionType = shape({
  value: string,
  text: string,
  disabled: bool,
  tooltip: string,
});

class CustomSelect extends React.PureComponent {
  static propTypes = {
    className: string,
    onChange: func,
    onOptionHover: func,
    signPosition: oneOf(['left', 'right']),
    signClass: string,
    listClassName: string,
    options: arrayOf(optionType),
    groupedOptions: arrayOf(shape({ caption: string, options: arrayOf(optionType) })),
    selected: string,
    disabled: bool,
    orderedList: bool,
    withNumberOptions: bool,
  };

  static defaultProps = {
    signPosition: 'right',
    orderedList: false,
    withNumberOptions: false,
    groupedOptions: [],
    options: [],
  };

  constructor(props) {
    super();
    this.state = {
      selectedValue: props.selected,
      selectedText: this.getInitiallySelectedText(props),
      showingOptions: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedValue, showingOptions } = this.state;

    if (!selectedValue) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedText: this.getInitiallySelectedText(this.props),
      });
    }

    if (showingOptions && !prevState.showingOptions) {
      const { top, height } = this.container.getBoundingClientRect();
      this.listEl.setAttribute(
        'style',
        `max-height:calc(100vh - ${top + height + 5}px);overflow:auto;`
      );
    }
  }

  onBlur = () => {
    // eslint-disable-next-line no-undef
    if (this.container.contains(document.activeElement)) {
      return;
    }
    this.setState({ showingOptions: false });
  };

  onOptionOver = ev => {
    if (!this.props.onOptionHover) {
      return;
    }
    const value = ev.target.getAttribute('data-value');
    this.props.onOptionHover(value, ev.target);
  };

  onOptionOut = () => {
    if (typeof this.props.onOptionHover === 'function') {
      this.props.onOptionHover(null);
    }
  };

  getInitiallySelectedText = ({ selected, groupedOptions, options }) => {
    if (selected) {
      const allOptions = _.flatten(options.concat(_.pluck(groupedOptions, 'options')));
      const maybeOption = _.findWhere(allOptions, { value: selected });
      return maybeOption ? maybeOption.text : '&zwnj;';
    }
    return '&zwnj;';
  };

  handleSelect = option => evt => {
    evt.stopPropagation();
    evt.preventDefault();

    // ignore disabled option selection
    if (option.disabled) {
      return;
    }

    // close window on self selection
    if (option.value === this.state.selectedValue) {
      this.setState({
        showingOptions: false,
      });
      return;
    }

    // close window and set currently selected option
    this.setState({
      selectedValue: option.value,
      selectedText: option.text,
      showingOptions: false,
    });

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(option.value);
    }
  };

  listRef = el => {
    this.listEl = el;
  };

  toggleOptions = () => {
    if (!this.props.disabled) {
      this.setState({ showingOptions: !this.state.showingOptions });
    }
  };

  renderOptionsGroup = (group, idx) => {
    const { caption, options } = group;
    return (
      <li className="options-group" key={idx}>
        <div className="options-group__caption">{caption}</div>
        <div className="options-group__options">
          <ul>{options.map(this.renderOption)}</ul>
        </div>
      </li>
    );
  };

  renderOption = (option, idx) => {
    const { value, text, disabled, tooltip } = option;
    const isSelected = this.state.selectedValue === value;
    const itemClass = classNames('option', stringUtils.dasherize(value), { selected: isSelected });

    return (
      <li
        className={itemClass}
        tabIndex="1"
        data-index={idx}
        data-value={value}
        onMouseDown={this.handleSelect(option)}
        key={idx}
        disabled={disabled}
        data-tip={tooltip || ''}>
        {text}
      </li>
    );
  };

  renderSign = () => {
    const { signClass, signPosition } = this.props;
    const content = signClass === 'full-triangle' ? <ArrowDown /> : '';
    const signClassName = classNames('select-sign', signClass, {
      left: signPosition === 'left',
      right: signPosition === 'right',
    });
    return <div className={signClassName}>{content}</div>;
  };

  render() {
    const containerClass = classNames('options-select-container', this.props.className, {
      expanded: this.state.showingOptions,
      disabled: this.props.disabled,
    });
    const optionsClass = classNames('options-container', this.props.listClassName, {
      visible: this.state.showingOptions,
      'with-numbered-options': this.props.withNumberOptions,
    });
    const ListTag = this.props.orderedList ? 'ol' : 'ul';

    return (
      <div
        className={containerClass}
        ref={el => {
          this.container = el;
        }}>
        <div
          className="select-input"
          onClick={this.toggleOptions}
          tabIndex="1"
          onBlur={this.onBlur}>
          {this.props.signPosition === 'left' && this.renderSign()}
          <div className="text-box" dangerouslySetInnerHTML={{ __html: this.state.selectedText }} />
          {this.props.signPosition === 'right' && this.renderSign()}
        </div>
        <ListTag
          className={optionsClass}
          onMouseOver={this.onOptionOver}
          onMouseLeave={this.onOptionOut}
          ref={this.listRef}>
          {this.props.groupedOptions.map(this.renderOptionsGroup)}
          {this.props.options.map(this.renderOption)}
        </ListTag>
      </div>
    );
  }
}

export default CustomSelect;
