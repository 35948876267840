import classNames from 'classnames';
import React from 'react';
import ReactModal from 'react-modal';
import _ from 'underscore';
import { bool, string, oneOf, node, number } from 'prop-types';

// when closeTimeoutMS prop is provided one should remember to change transition time in css because
// closeTimeoutMS and animationTime used in .ReactModal__Overlay and .ep-modal should be the same

class Modal extends React.Component {
  static propTypes = {
    className: string,
    closeTimeoutMS: number,
    children: node,
    isOpen: bool.isRequired,
    modalSize: oneOf(['small', 'auto', 'standard', 'medium', 'large', 'full-size']),
    overlayClassName: string,
    position: oneOf(['top', 'centered']),
    scrollableContent: bool,
    title: string,
  };

  static defaultProps = {
    className: '',
    closeTimeoutMS: 500,
    children: null,
    modalSize: 'full-size',
    overlayClassName: '',
    position: 'centered',
    scrollableContent: false,
    title: null,
  };

  render() {
    const {
      className,
      closeTimeoutMS,
      children,
      isOpen,
      title,
      modalSize,
      overlayClassName,
      position,
      scrollableContent,
      ...props
    } = this.props;

    return (
      <ReactModal
        className={classNames(
          'ep-modal',
          modalSize,
          { 'scrolling-content': scrollableContent },
          className
        )}
        closeTimeoutMS={closeTimeoutMS}
        isOpen={isOpen}
        overlayClassName={classNames('ep-modal__overlay', position, overlayClassName, {
          'scrolling-container': !scrollableContent,
        })}
        {...props}>
        <div className="ep-modal__content">
          {!_.isEmpty(title) && <h2>{title}</h2>}
          {children}
        </div>
      </ReactModal>
    );
  }
}

export default Modal;
