import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Card = ({ children, className, style }) => (
  <div className={classnames('ep-card', className)} style={style}>
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object, //eslint-disable-line
};

Card.defaultProps = {
  className: '',
  style: {},
};

export default Card;
