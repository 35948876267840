import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ className, disabled, label, name, onChange, checked }) => {
  const labelClasses = classNames('ep-checkbox', className, { disabled });

  return (
    <label className={labelClasses}>
      <input
        checked={checked}
        className="ep-checkbox__input"
        disabled={disabled}
        name={name}
        onChange={onChange}
        type="checkbox"
      />
      {label && <span className="ep-checkbox__label">{label}</span>}
    </label>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

Checkbox.defaultProps = {
  className: '',
  disabled: false,
};

export default Checkbox;
