const upperLettersRegex = /[A-Z]/g;
const dashUnderscoreSpaceRegex = /[-_\s]+/g;

function makeString(maybeStr) {
  if (maybeStr === null || maybeStr === undefined) return '';
  return `${maybeStr}`;
}

function startsWith(str, prefix) {
  const madeStr = makeString(str);
  const madePrefix = makeString(prefix);
  return madeStr.indexOf(madePrefix) === 0;
}

function endsWith(str, suffix) {
  const madeStr = makeString(str);
  const madeSuffix = makeString(suffix);
  const position = madeStr.length - madeSuffix.length;
  return position >= 0 && madeStr.indexOf(madeSuffix, position) === position;
}

function dasherize(str) {
  return makeString(str)
    .trim()
    .replace(upperLettersRegex, '-$1')
    .replace(dashUnderscoreSpaceRegex, '-')
    .toLowerCase();
}

export default {
  startsWith,
  endsWith,
  dasherize,
};
