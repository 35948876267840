import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../styles/components/iconButton.styl';

const IconButton = props => {
  const { iconName, label, disabled, displayLabel, title, className, onClick } = props;

  const classes = classNames(className, 'btn-icon-container', { disabled });
  const buttonClasses = classNames('btn', 'btn-icon', iconName, { disabled });
  const clickHandler = evt => {
    if (!disabled) {
      onClick(evt);
    }
  };

  return (
    <div className={classes} onClick={clickHandler}>
      <div className={buttonClasses} data-tip={title} />
      {displayLabel && <div className="label-text">{label}</div>}
    </div>
  );
};

IconButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  displayLabel: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  onClick: () => {},
  label: null,
  title: null,
  className: null,
  disabled: false,
  displayLabel: false,
};

export default IconButton;
