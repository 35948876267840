import React from 'react';
import { string, func, bool, instanceOf } from 'prop-types';
import Immutable from 'immutable';
import classnames from 'classnames';
import RadioWithLabel from './RadioWithLabel';

const RadioButtonList = ({
  name,
  editable,
  additionalOptions,
  selectedOption,
  options,
  onChange,
  className,
  sectionId,
}) => {
  const classes = classnames('ep-radiobuttonlist-fieldset', className);
  return (
    <fieldset name={name} disabled={!editable} className={classes}>
      {options.map(option =>
        (
          <RadioWithLabel
            key={option.get('value')}
            onChange={onChange}
            option={option}
            sectionId={sectionId}
            selectedOption={selectedOption}
          />
        )).toList()}
      {!additionalOptions.isEmpty() && <hr />}
      {additionalOptions.map(option =>
        (
          <RadioWithLabel
            key={option.get('value')}
            onChange={onChange}
            option={option}
            sectionId={sectionId}
            selectedOption={selectedOption}
          />
        )).toList()}
    </fieldset>
  );
};

RadioButtonList.propTypes = {
  additionalOptions: instanceOf(Immutable.List),
  className: string,
  editable: bool,
  name: string.isRequired,
  onChange: func,
  options: instanceOf(Immutable.List).isRequired,
  sectionId: string.isRequired,
  selectedOption: string,
};

RadioButtonList.defaultProps = {
  additionalOptions: Immutable.List(),
  onChange: () => null,
  editable: true,
  className: '',
  selectedOption: '',
};

export default RadioButtonList;
