import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Checkbox from './Checkbox';
import ExpandButton from '../Buttons/ExpandButton';
import GroupedCheckboxes from './GroupedCheckboxes';
import Popover from '../other/Popover';

const optionType = PropTypes.shape({
  value: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
});

const isOptionSelected = selectedOptions => option => _.contains(selectedOptions, option.value);

const flattenOptions = selectedOptions =>
  _.chain(selectedOptions)
    .values()
    .flatten()
    .value();

const optionsFromGroup = groupedOptions =>
  _.chain(groupedOptions)
    .map(group => group.options)
    .flatten()
    .value();

const pluckValueFromOptions = (allSelected, options) =>
  allSelected ? [] : _.pluck(options, 'value');

const onChangeOptions = (selectedOptions, value) =>
  _.contains(selectedOptions, value)
    ? _.without(selectedOptions, value)
    : [...selectedOptions, value];

export default class SelectWithCheckboxes extends Component {
  static propTypes = {
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    defaultLabel: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    groupedOptions: PropTypes.objectOf(
      PropTypes.shape({
        caption: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(optionType).isRequired,
      })
    ),
    options: PropTypes.arrayOf(optionType),
    onChange: PropTypes.func.isRequired,
    selectAllLabel: PropTypes.string,
    selectedOptions: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string).isRequired,
      PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    ]).isRequired,
    triggerClassName: PropTypes.string,
    withSelectAll: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    containerClassName: '',
    disabled: false,
    defaultLabel: 'select',
    options: [],
    groupedOptions: {},
    selectAllLabel: 'select all',
    triggerClassName: '',
    withSelectAll: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      opened: false,
    };

    this.allOptionsSelected = this.allOptionsSelected.bind(this);
    this.getTriggerLabel = this.getTriggerLabel.bind(this);
    this.hideOptions = this.hideOptions.bind(this);
    this.isOptionSelected = this.isOptionSelected.bind(this);
    this.storeContainerWidth = this.storeContainerWidth.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this._containerRef = this._containerRef.bind(this);
  }

  componentDidMount() {
    this.storeContainerWidth();
    window.addEventListener('resize', this.storeContainerWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.storeContainerWidth);
  }

  allOptionsSelected() {
    const { groupedOptions, options, selectedOptions } = this.props;
    const allAvailableOptions = flattenOptions(optionsFromGroup(groupedOptions).concat(options));
    const allSelectedOptions = flattenOptions(selectedOptions);
    return allAvailableOptions.length === allSelectedOptions.length;
  }

  getTriggerLabel() {
    const { defaultLabel, groupedOptions, options, selectedOptions } = this.props;
    const allOptions = optionsFromGroup(groupedOptions).concat(options);
    const flattenedSelectedOptions = flattenOptions(selectedOptions);
    const filteredOptions = _.chain(allOptions)
      .filter(isOptionSelected(flattenedSelectedOptions))
      .pluck('text')
      .value();

    return _.isEmpty(flattenedSelectedOptions) ? defaultLabel : filteredOptions.join(', ');
  }

  hideOptions() {
    this.setState({ opened: false });
  }

  onChange = (groupId, value) => () => {
    const { groupedOptions, onChange, selectedOptions } = this.props;
    if (_.isEmpty(groupedOptions) && groupId === 'ungrouped') {
      const options = onChangeOptions(selectedOptions, value);
      onChange(options);
    } else {
      const selectedOptionsInGroup = onChangeOptions(selectedOptions[groupId], value);
      const options = _.extend(selectedOptions, {
        [groupId]: selectedOptionsInGroup,
      });
      onChange(options);
    }
  };

  toggleOptions() {
    if (this.props.disabled) return;
    this.setState({ opened: !this.state.opened });
  }

  toggleAll() {
    const { onChange, groupedOptions, options, selectedOptions } = this.props;
    const allOptionsAreSelected = this.allOptionsSelected();
    if (_.isEmpty(groupedOptions)) {
      const selectOptions = pluckValueFromOptions(allOptionsAreSelected, options);
      onChange(selectOptions);
    } else {
      const ungrouped = pluckValueFromOptions(allOptionsAreSelected, options);
      const grouped = _.reduce(
        groupedOptions,
        (acc, group, groupId) => {
          acc[groupId] = pluckValueFromOptions(allOptionsAreSelected, group.options);
          return acc;
        },
        {}
      );
      onChange({ ungrouped, ...grouped });
    }
  }

  _containerRef(el) {
    this.container = el;
  }

  storeContainerWidth() {
    this.setState({ containerWidth: this.container.offsetWidth });
  }

  isOptionSelected(groupId, value) {
    const { groupedOptions, selectedOptions } = this.props;
    return _.isEmpty(groupedOptions) && groupId === 'ungrouped'
      ? _.contains(selectedOptions, value)
      : _.contains(selectedOptions[groupId], value);
  }

  render() {
    const {
      className,
      containerClassName,
      disabled,
      groupedOptions,
      name,
      options,
      selectAllLabel,
      selectedOptions,
      triggerClassName,
      withSelectAll,
    } = this.props;
    const containerClasses = classNames('ep-select-with-checkboxes__container', containerClassName);
    const popoverClasses = classNames('ep-select-with-checkboxes', className);
    const triggerClasses = classNames('ep-select-with-checkboxes__trigger', triggerClassName, {
      disabled,
    });
    return (
      <Popover
        className={popoverClasses}
        visible={this.state.opened}
        onRequestClose={this.hideOptions}
        positionParams={{ positionOffset: -1 }}>
        <div ref={this._containerRef} className={triggerClasses} onClick={this.toggleOptions}>
          <div className="trigger__label">{this.getTriggerLabel()}</div>
          <ExpandButton onClick={this.toggleOptions} expanded={this.state.opened} />
        </div>
        <div style={{ minWidth: `${this.state.containerWidth}px` }}>
          <div className={containerClasses}>
            {withSelectAll && (
              <Checkbox
                checked={this.allOptionsSelected()}
                className="select-all"
                name={name}
                label={selectAllLabel}
                onChange={this.toggleAll}
              />
            )}
            {!_.isEmpty(groupedOptions) && (
              <GroupedCheckboxes
                groupedOptions={groupedOptions}
                name={name}
                onChange={this.onChange}
                selectedOptions={selectedOptions}
              />
            )}
            {options.map(option => (
              <Checkbox
                checked={this.isOptionSelected('ungrouped', option.value)}
                key={option.value}
                name={name}
                label={option.text}
                onChange={this.onChange('ungrouped', option.value)}
              />
            ))}
          </div>
        </div>
      </Popover>
    );
  }
}
