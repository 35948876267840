import React from 'react';
import { string } from 'prop-types';
import '../../styles/components/guidelineLink.styl';

const GuidelineLink = ({
  authors,
  profileTitle,
  guidelineTitle,
  profileId,
  guidelineId,
  baseUrl,
}) => {
  const profileUrl = `${baseUrl}/profile/${profileId}`;
  const guidelineUrl = `${baseUrl}/guideline/${guidelineId}`;
  return (
    <div>
      {authors && <div className="ep-guideline-link-authors">{authors}</div>}
      <div className="ep-guideline-link-profile">
        <a
          href={profileUrl}
          target="_blank"
          rel="noopener noreferrer"
          dangerouslySetInnerHTML={{ __html: profileTitle }}
        />
      </div>
      <div className="ep-guideline-link-guideline">
        <a href={guidelineUrl} target="_blank" rel="noopener noreferrer">
          {guidelineTitle}
        </a>
      </div>
    </div>
  );
};

GuidelineLink.propTypes = {
  authors: string,
  profileTitle: string.isRequired,
  guidelineTitle: string.isRequired,
  profileId: string.isRequired,
  guidelineId: string.isRequired,
  baseUrl: string.isRequired,
};

GuidelineLink.defaultProps = {
  authors: null,
};

export default GuidelineLink;
